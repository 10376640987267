import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
    this.showPanel(0); // Show the first panel (Summary) by default
  }

  showPanel(eventOrIndex) {
    const index = typeof eventOrIndex === "number" ? eventOrIndex : this.tabTargets.indexOf(eventOrIndex.currentTarget);

    this.tabTargets.forEach((tab, i) => {
      tab.classList.toggle("active", i === index);
      tab.setAttribute("aria-selected", i === index); // Update aria-selected attribute
    });

    this.panelTargets.forEach((panel, i) => {
      panel.classList.toggle("hidden", i !== index);
    });
  }
}