// app/javascript/controllers/role_selector_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["department", "role"]

  connect() {
    this.updateRoles() // Initialize roles on page load if a department is preselected
  }

  updateRoles() {
    const department = this.departmentTarget.value
    let rolesData;

    // Attempt to parse roles data, with a fallback if it fails
    try {
      rolesData = JSON.parse(this.roleTarget.dataset.roles)
    } catch (e) {
      rolesData = {} // Fallback to an empty object if JSON parse fails
    }

    const roles = rolesData[department] || []

    this.roleTarget.innerHTML = "<option value=''>Select Role</option>"
    roles.forEach(role => {
      const option = document.createElement("option")
      option.value = role
      option.textContent = role
      this.roleTarget.appendChild(option)
    })
  }
}
