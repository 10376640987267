import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input", "dropZone", "progressBar", "progressBarFill", "progressText", "submitButton"]

  connect() {
    console.log("Dropzone connected")
    this.dropZoneTarget.addEventListener('drop', this.preventDefaults)
    this.dropZoneTarget.addEventListener('dragover', this.preventDefaults)
    this.dropZoneTarget.addEventListener('dragleave', this.preventDefaults)
  }

  disconnect() {
    this.dropZoneTarget.removeEventListener('drop', this.preventDefaults)
    this.dropZoneTarget.removeEventListener('dragover', this.preventDefaults)
    this.dropZoneTarget.removeEventListener('dragleave', this.preventDefaults)
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragOver(e) {
    this.preventDefaults(e)
    this.dropZoneTarget.classList.add('border-indigo-600')
  }

  handleDragLeave(e) {
    this.preventDefaults(e)
    this.dropZoneTarget.classList.remove('border-indigo-600')
  }

  handleDrop(e) {
    this.preventDefaults(e)
    this.dropZoneTarget.classList.remove('border-indigo-600')
    
    const files = e.dataTransfer.files
    if (files.length > 0) {
      this.inputTarget.files = files
      this.uploadFile(files[0])
    }
  }

  handleInput(e) {
    const file = this.inputTarget.files[0]
    if (file) {
      this.uploadFile(file)
    }
  }

  uploadFile(file) {
    this.progressBarTarget.classList.remove('hidden')
    this.submitButtonTarget.disabled = true
    
    const upload = new DirectUpload(file, this.inputTarget.dataset.directUploadUrl, {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener("progress", (event) => {
          const progress = (event.loaded / event.total) * 100
          this.progressBarFillTarget.style.width = `${progress}%`
          this.progressTextTarget.textContent = `${Math.round(progress)}%`
        })
      }
    })

    upload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("value", blob.signed_id)
        hiddenField.name = this.inputTarget.name
        this.inputTarget.form.appendChild(hiddenField)
        
        this.submitButtonTarget.disabled = false
        this.progressTextTarget.textContent = "Upload complete!"
      }
    })
  }
}