import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  static values = {
    dismissAfter: { type: Number, default: 5000 } // 5 seconds by default
  }

  connect() {
    // Set a timeout to dismiss the notification
    this.timeout = setTimeout(() => {
      this.dismiss()
    }, this.dismissAfterValue)
  }

  disconnect() {
    // Clear the timeout if the element is removed from the DOM
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  dismiss() {
    // Fade out and remove the notification
    this.element.classList.add('opacity-0', 'transition-opacity')
    setTimeout(() => {
      this.element.remove()
    }, 300) // Remove after transition completes
  }
}
